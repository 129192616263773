import React from 'react'
import Link from 'gatsby-link'

function Footer() {
  const year = new Date().getFullYear()

  return (
    <footer>
      <nav>
        <ul>
          <li>
            <Link to="/apps/" rel="nofollow">
              {'Apps'}
            </Link>
          </li>
          <li>
            <Link to="/support/" rel="nofollow">
              {'Support'}
            </Link>
          </li>
          <li>
            <Link to="/terms/" rel="nofollow">
              {'Terms of Service'}
            </Link>
          </li>
          <li>
            <Link to="/privacy/" rel="nofollow">
              {'Privacy Policy'}
            </Link>
          </li>
          <li>
            <Link to="/contact/" rel="nofollow">
              {'Contact'}
            </Link>
          </li>
        </ul>
      </nav>
      <p className="center">
        <small>{`Copyright ©${year} Adrian Gray. All rights reserved.`}</small>
      </p>
    </footer>
  )
}

export default Footer

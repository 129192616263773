import React, { useState, useEffect } from 'react'

function Banner() {
  const [style, setStyle] = useState({
    width: '468px',
    height: '60px',
  })
  const [img, setImg] = useState('')
  const [link, setLink] = useState('')
  const [alt, setAlt] = useState('')

  useEffect(() => {
    if (Math.random() > 0.5) {
      setImg('/assets/images/japanesePod101.png')
      setLink('https://www.japanesepod101.com/member/go.php?r=955225&amp;i=b31')
      setAlt('Learn Japanese with JapanesePod101.com')
    } else {
      setImg('/assets/images/learn-japanese.png')
      setLink('https://gumroad.com/a/643224691')
      setAlt('Learn Japanese anywhere with Master Japanese')
    }
    if (parseInt(document.body.clientWidth) < 468) {
      setStyle({
        width: '100%',
        height: 'auto',
      })
    }
  }, [])

  return (
    <div className="banner">
      <div className="content" style={style}>
        <a href={link}>
          <img src={img} border="0" alt={alt} />
        </a>
      </div>
      <small>advertisement</small>
    </div>
  )
}

export default Banner
